<template>
  <div class="login-box">
    <div class="login-logo">
      <!-- <svg-icon icon-class="logo" /> -->
      <h1>{{isTest ? '【测试环境】':''}}黑天鹅管理后台</h1>
    </div>
    <a-form-model layout="horizontal" :model="formInline" @submit.prevent="handleSubmit">
      <a-form-model-item>
        <a-input v-model="formInline.username" size="large" placeholder="请输入用户名">
          <template #prefix><a-icon type="user" /></template>
        </a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-input v-model="formInline.password" size="large" type="password" placeholder="请输入密码" autocomplete="new-password">
          <template #prefix><a-icon type="safety" /></template>
        </a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-button
          type="primary"
          html-type="submit"
          size="large"
          :loading="loading"
          block
        >
          登录
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { LOGIN_ACCOUNT } from "@/store/mutation-types";
import {message} from 'ant-design-vue'

import store from '@/store/index'
import config from '@/utils/config.js'

export default {
  name: "Login",
  data(){
    return {
      isTest: config.ENV_TEST,

      formInline: {
        username: localStorage.getItem(LOGIN_ACCOUNT) || "",
        password: '',
      },
      loading: false,
    }
  },
  methods:{
    async handleSubmit(){
      const {username, password} = this.formInline
      if(username.trim() == '' || password.trim() == '') return message.warning('用户名或密码不能为空！')
      // message.loading('登录中...', 3000)
      this.loading = true
      
      const params = {
        account: username,
        password
      }
      const res =  await store.dispatch('login', params).finally(() => {
        this.loading = false
        message.destroy()
      })
     
      if (res.code == 0) {
        message.success('登录成功！')
        localStorage.setItem(LOGIN_ACCOUNT, username)
       
        // test todo 登录后打开菜单第一项
        if(res.data.menu_list.length>0){
          const toPath = res.data.menu_list[0].submenu_list.length>0 ? res.data.menu_list[0].submenu_list[0].path : '/'
          this.$router.push(toPath)
        }
        // const toPath = decodeURIComponent((this.$route.query?.redirect || '/'))
        // this.$router.replace(toPath).then(() => {
        //   if (this.$route.name == 'login') {
        //     this.$router.replace('/')
        //   }
        // })
      } else {
        message.error(res.message || '登录失败')
        localStorage.setItem(LOGIN_ACCOUNT, "")
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login-box {
  width: 100vw;
  height: 100vh;
  display: flex;
  padding-top: 240px;
  flex-direction: column;
  align-items: center;
  background: url("~@/assets/login.svg");
  background-size: 100%;

  .login-logo {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .svg-icon {
      font-size: 48px;
    }

    img {
      height: 48px;
    }
    h1 {
      // margin-left: 10px;
      margin-bottom: 0;
    }
  }

    ::v-deep(.ant-form) {
      width: 400px;

      .ant-col {
        width: 100%;
      }
      .ant-form-item-label {
        padding-right: 6px;
      }
  }
}
</style>
